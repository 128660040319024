import exerciseLibrary from "../data/exerciseLibrary";

const WorkoutGenerator = {
  generateWorkout: (preferences) => {
    console.log("Generating workout with preferences:", preferences);

    let filteredExercises = exerciseLibrary;

    // Apply filters only if they result in at least 3 exercises
    const applyFilter = (filterFn) => {
      const filtered = filteredExercises.filter(filterFn);
      if (filtered.length >= 3) {
        filteredExercises = filtered;
      }
    };

    // Apply filters
    if (preferences.fitnessExperience) {
      applyFilter(exercise => exercise.difficulty.toLowerCase() === preferences.fitnessExperience.toLowerCase());
    }

    if (!preferences.hasGymAccess) {
      applyFilter(exercise => exercise.equipment.toLowerCase() === 'bodyweight');
    }

    if (preferences.workoutTypes.length > 0) {
      applyFilter(exercise => preferences.workoutTypes.some(type => 
        exercise.type.some(t => t.toLowerCase() === type.toLowerCase())
      ));
    }

    if (preferences.fitnessGoals.length > 0) {
      applyFilter(exercise => preferences.fitnessGoals.some(goal => 
        exercise.targetGoals.some(g => g.toLowerCase().includes(goal.toLowerCase()))
      ));
    }

    if (preferences.bodyParts.length > 0) {
      applyFilter(exercise => preferences.bodyParts.some(part => 
        exercise.bodyPart.toLowerCase() === part.toLowerCase() || 
        (part.toLowerCase() === "full body" && exercise.bodyPart.toLowerCase() === "full body")
      ));
    }

    console.log("Filtered exercises:", filteredExercises);

    if (filteredExercises.length === 0) {
      throw new Error("No suitable exercises found for the given preferences");
    }

    const exerciseCount = getExerciseCount(preferences.workoutDuration);
    const selectedExercises = selectExercises(filteredExercises, exerciseCount);

    console.log("Selected exercises:", selectedExercises);

    return selectedExercises.map((exercise) => ({
      ...exercise,
      sets: getSetsCount(preferences.fitnessExperience),
      reps: getRepsCount(preferences.fitnessExperience),
      rest: getRestTime(preferences.fitnessExperience),
    }));
  },
};

function getExerciseCount(duration) {
  switch (duration) {
    case "15 mins": return 3;
    case "30 mins": return 5;
    case "1 hour": return 8;
    default: return 5;
  }
}

function selectExercises(exercises, count) {
  const shuffled = shuffleArray(exercises);
  return shuffled.slice(0, count);
}

function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

function getSetsCount(experience) {
  switch (experience.toLowerCase()) {
    case "beginner": return 2;
    case "intermediate": return 3;
    case "advanced": return 4;
    default: return 3;
  }
}

function getRepsCount(experience) {
  switch (experience.toLowerCase()) {
    case "beginner": return "10-12";
    case "intermediate": return "8-10";
    case "advanced": return "6-8";
    default: return "8-12";
  }
}

function getRestTime(experience) {
  switch (experience.toLowerCase()) {
    case "beginner": return 60;
    case "intermediate": return 45;
    case "advanced": return 30;
    default: return 45;
  }
}

export default WorkoutGenerator;
