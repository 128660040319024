"use client";

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { auth } from "./Firebase/Firebase";
import { signOut } from "firebase/auth";
import {
  Activity,
  Dumbbell,
  Timer,
  Target,
  TrendingUp,
  LogOut,
  LogIn,
  User,
} from "lucide-react";
import { Button } from "./components/ui/button";
import { Card, CardContent } from "./components/ui/card";
import SignIn from "./components/SignIn";
import WorkoutDesigner from "./components/WorkoutDesigner";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import UserDashboard from "./components/UserDashboard";
import { ToastProvider } from "./components/ui/toast";
import { Toaster } from "./components/ui/toaster";

export default function App() {
  const [showSignIn, setShowSignIn] = useState(false);

  return (
    <AuthProvider>
      <ToastProvider>
        <Router>
          <div className="min-h-screen flex flex-col bg-gray-50 text-gray-900">
            <Header setShowSignIn={setShowSignIn} />
            <main className="flex-grow pt-16">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/workout-designer" element={<WorkoutDesigner />} />
                <Route path="/user-dashboard" element={<UserDashboard />} />
              </Routes>
            </main>
          </div>
          {showSignIn && <SignIn onClose={() => setShowSignIn(false)} />}
        </Router>
        <Toaster />
      </ToastProvider>
    </AuthProvider>
  );
}

function Header({ setShowSignIn }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setShowSignIn(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <header className="bg-white shadow-sm fixed w-full z-10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <Activity className="h-6 w-6 text-green-600 transition-transform duration-300 hover:scale-110" />
              <span className="ml-2 text-lg font-bold text-gray-900">
                Kavari
              </span>
            </Link>
          </div>
          <div className="flex items-center space-x-4">
            {user && (
              <Button
                onClick={() => navigate("/user-dashboard")}
                variant="ghost"
                size="sm"
                className="text-sm group"
              >
                <User className="mr-2 h-4 w-4 transition-transform duration-300 group-hover:scale-110" />
                Dashboard
              </Button>
            )}
            {user ? (
              <Button
                onClick={handleSignOut}
                variant="ghost"
                size="sm"
                className="text-sm group"
              >
                <LogOut className="mr-2 h-4 w-4 transition-transform duration-300 group-hover:rotate-12" />
                Sign Out
              </Button>
            ) : (
              <Button
                onClick={() => setShowSignIn(true)}
                variant="ghost"
                size="sm"
                className="text-sm group"
              >
                <LogIn className="mr-2 h-4 w-4 transition-transform duration-300 group-hover:-translate-x-1" />
                Sign In
              </Button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

function Home() {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/user-dashboard");
    }
  }, [user, navigate]);

  if (user) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-8rem)] p-4 text-center bg-gradient-to-b from-gray-50 to-gray-100 -mt-16">
      <Card className="w-full max-w-[90vw] md:max-w-md bg-white shadow-lg rounded-2xl overflow-hidden">
        <CardContent className="p-6">
          <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-900 leading-tight">
            Create Your Perfect{" "}
            <span className="text-green-600 relative">
              Workout
              <span className="absolute bottom-0 left-0 w-full h-1 bg-green-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
            </span>{" "}
            in Minutes
          </h1>
          <div className="grid grid-cols-3 gap-2 sm:gap-4 mb-6">
            <div className="flex flex-col items-center justify-center p-2 sm:p-4 rounded-xl group">
              <Timer className="h-6 w-6 sm:h-8 sm:w-8 mb-2 text-green-600 transition-transform duration-300 group-hover:scale-110" />
              <span className="text-xs sm:text-sm font-medium text-gray-700">
                Personal
              </span>
            </div>
            <div className="flex flex-col items-center justify-cente p-2 sm:p-4 rounded-xl group">
              <Target className="h-6 w-6 sm:h-8 sm:w-8 mb-2 text-green-600 transition-transform duration-300 group-hover:rotate-45" />
              <span className="text-xs sm:text-sm font-medium text-gray-700">
                Rewarding
              </span>
            </div>
            <div className="flex flex-col items-center justify-cente p-2 sm:p-4 rounded-xl group">
              <TrendingUp className="h-6 w-6 sm:h-8 sm:w-8 mb-2 text-green-600 transition-transform duration-300 group-hover:translate-y-[-4px]" />
              <span className="text-xs sm:text-sm font-medium text-gray-700">
                Fun
              </span>
            </div>
          </div>
          <Button
            size="lg"
            className="w-full bg-green-600 hover:bg-green-700 text-white text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 rounded-full transition-all duration-300 transform hover:scale-105 shadow-md group"
            onClick={() => navigate("/workout-designer")}
          >
            <Dumbbell className="mr-2 h-4 w-4 sm:h-5 sm:w-5 transition-transform duration-300 group-hover:rotate-12" />
            Let's Get Started
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}
