"use client";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Slider } from "../components/ui/slider";
import { cn } from "../lib/utils";
import {
  User,
  Cake,
  Target,
  Dumbbell,
  Clock,
  Home,
  Power,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";
import { ScrollArea } from "../components/ui/scroll-area";

const QuestionDesigner = ({ onPreferencesSubmit, showForm }) => {
  const location = useLocation();
  const [step, setStep] = useState(location.state?.step || 1);
  const [preferences, setPreferences] = useState(location.state?.preferences || {
    firstName: "",
    gender: "",
    ageRange: "",
    weightRange: "",
    fitnessGoals: [],
    fitnessExperience: "",
    workoutDuration: 30,
    workoutTypes: [],
    hasGymAccess: null,
    bodyParts: [],
  });

  useEffect(() => {
    if (!showForm) {
      setPreferences({
        firstName: "",
        gender: "",
        ageRange: "",
        weightRange: "",
        fitnessGoals: [],
        fitnessExperience: "",
        workoutDuration: 30,
        workoutTypes: [],
        hasGymAccess: null,
        bodyParts: [],
      });
      setStep(1);
    }
  }, [showForm]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMultiSelect = (name, value) => {
    setPreferences((prev) => ({
      ...prev,
      [name]: prev[name].includes(value)
        ? prev[name].filter((item) => item !== value)
        : [...prev[name], value],
    }));
  };

  const handleSliderChange = (value) => {
    setPreferences((prev) => ({ ...prev, workoutDuration: value[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onPreferencesSubmit(preferences);
  };

  const handleNext = () => {
    if (step < 4) {
      setStep((prev) => prev + 1);
      window.scrollTo(0, 0);
    } else {
      handleSubmit(new Event("submit"));
      window.scrollTo(0, 0);
    }
  };

  const handlePrev = () => {
    setStep((prev) => Math.max(prev - 1, 1));
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <div>
              <Label
                htmlFor="firstName"
                className="text-sm font-medium text-gray-700 flex items-center"
              >
                <User className="w-4 h-4 mr-2" />
                What's your first name?
              </Label>
              <Input
                id="firstName"
                name="firstName"
                value={preferences.firstName}
                onChange={handleChange}
                required
                className="mt-1 bg-white text-gray-900 focus:ring-primary focus:border-primary"
                placeholder="Enter your first name"
              />
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <User className="w-4 h-4 mr-2" />
                Gender:
              </Label>
              <div className="grid grid-cols-3 gap-2">
                {["Male", "Female", "Other"].map((option) => (
                  <Button
                    key={option}
                    type="button"
                    variant={
                      preferences.gender === option ? "default" : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.gender === option
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() =>
                      handleChange({
                        target: { name: "gender", value: option },
                      })
                    }
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Cake className="w-4 h-4 mr-2" />
                Which age group do you belong to?
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {["under 18", "18-30", "30-45", "45+"].map((option) => (
                  <Button
                    key={option}
                    type="button"
                    variant={
                      preferences.ageRange === option ? "default" : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.ageRange === option
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() =>
                      handleChange({
                        target: { name: "ageRange", value: option },
                      })
                    }
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <User className="w-4 h-4 mr-2" />
                What's your weight range?
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {["Under 50kg", "50-70kg", "70-90kg", "Over 90kg"].map(
                  (option) => (
                    <Button
                      key={option}
                      type="button"
                      variant={
                        preferences.weightRange === option
                          ? "default"
                          : "outline"
                      }
                      className={cn(
                        "w-full",
                        preferences.weightRange === option
                          ? "bg-primary text-primary-foreground"
                          : "text-gray-700 hover:bg-primary/10"
                      )}
                      onClick={() =>
                        handleChange({
                          target: { name: "weightRange", value: option },
                        })
                      }
                    >
                      {option}
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Target className="w-4 h-4 mr-2" />
                What are your fitness goals? (Select Multiple)
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  "Lose Weight",
                  "Build Muscle",
                  "Improve Cardio",
                  "Increase Flexibility",
                ].map((goal) => (
                  <Button
                    key={goal}
                    type="button"
                    variant={
                      preferences.fitnessGoals.includes(goal)
                        ? "default"
                        : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.fitnessGoals.includes(goal)
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() => handleMultiSelect("fitnessGoals", goal)}
                  >
                    {goal}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Dumbbell className="w-4 h-4 mr-2" />
                How would you describe your fitness experience?
              </Label>
              <div className="grid grid-cols-3 gap-1">
                {["Beginner", "Intermediate", "Advanced"].map((level) => (
                  <Button
                    key={level}
                    type="button"
                    variant={
                      preferences.fitnessExperience === level
                        ? "default"
                        : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.fitnessExperience === level
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() =>
                      handleChange({
                        target: { name: "fitnessExperience", value: level },
                      })
                    }
                  >
                    {level}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Dumbbell className="w-4 h-4 mr-2" />
                What types of workouts do you prefer? (Select all that apply)
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  "Strength Training",
                  "Cardio",
                  "Flexibility",
                  "High-Intensity",
                ].map((type) => (
                  <Button
                    key={type}
                    type="button"
                    variant={
                      preferences.workoutTypes.includes(type)
                        ? "default"
                        : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.workoutTypes.includes(type)
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() => handleMultiSelect("workoutTypes", type)}
                  >
                    {type}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Target className="w-4 h-4 mr-2" />
                Which body parts would you like to focus on? (Select Multiple)
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {[
                  "Chest",
                  "Back",
                  "Legs",
                  "Arms",
                  "Shoulders",
                  "Core",
                  "Glutes",
                  "Full Body",
                ].map((part) => (
                  <Button
                    key={part}
                    type="button"
                    variant={
                      preferences.bodyParts.includes(part)
                        ? "default"
                        : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.bodyParts.includes(part)
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() => handleMultiSelect("bodyParts", part)}
                  >
                    {part}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                <Clock className="w-4 h-4 mr-2" />
                How long would you like your workout to be?
              </Label>
              <div className="text-sm text-gray-600 ml-6 mt-1">
                {preferences.workoutDuration} minutes
              </div>
              <Slider
                min={15}
                max={90}
                step={5}
                value={[preferences.workoutDuration]}
                onValueChange={handleSliderChange}
                className="mt-2"
              />
            </div>
            <div>
              <Label className="text-sm font-medium text-gray-700 flex items-center mb-2">
                {preferences.hasGymAccess ? (
                  <Power className="w-4 h-4 mr-2" />
                ) : (
                  <Home className="w-4 h-4 mr-2" />
                )}
                Do you have access to a gym or prefer home workouts?
              </Label>
              <div className="grid grid-cols-2 gap-2">
                {["Gym", "Home"].map((option) => (
                  <Button
                    key={option}
                    type="button"
                    variant={
                      preferences.hasGymAccess === (option === "Gym")
                        ? "default"
                        : "outline"
                    }
                    className={cn(
                      "w-full",
                      preferences.hasGymAccess === (option === "Gym")
                        ? "bg-primary text-primary-foreground"
                        : "text-gray-700 hover:bg-primary/10"
                    )}
                    onClick={() =>
                      handleChange({
                        target: {
                          name: "hasGymAccess",
                          value: option === "Gym",
                        },
                      })
                    }
                  >
                    {option}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-base font-semibold text-gray-900 flex items-center">
              <User className="w-5 h-5 mr-2" />
              Review Your Fitness Profile
            </h3>
            <ScrollArea className="h-[300px] rounded-md border p-4">
              <div className="space-y-4">
                <p>
                  <strong className="text-gray-700">Name:</strong>{" "}
                  <span className="text-gray-900">{preferences.firstName}</span>
                </p>
                <p>
                  <strong className="text-gray-700">Gender:</strong>{" "}
                  <span className="text-gray-900">{preferences.gender}</span>
                </p>
                <p>
                  <strong className="text-gray-700">Age Range:</strong>{" "}
                  <span className="text-gray-900">{preferences.ageRange}</span>
                </p>
                <p>
                  <strong className="text-gray-700">Weight Range:</strong>{" "}
                  <span className="text-gray-900">
                    {preferences.weightRange}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">Fitness Goals:</strong>{" "}
                  <span className="text-gray-900">
                    {preferences.fitnessGoals.join(", ")}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">Fitness Experience:</strong>{" "}
                  <span className="text-gray-900">
                    {preferences.fitnessExperience}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">
                    Preferred Workout Types:
                  </strong>{" "}
                  <span className="text-gray-900">
                    {preferences.workoutTypes.join(", ")}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">Focus Areas:</strong>{" "}
                  <span className="text-gray-900">
                    {preferences.bodyParts.join(", ")}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">Workout Location:</strong>{" "}
                  <span className="text-gray-900">
                    {preferences.hasGymAccess ? "Gym" : "Home"}
                  </span>
                </p>
                <p>
                  <strong className="text-gray-700">
                    Preferred Workout Duration:
                  </strong>{" "}
                  <span className="text-gray-900">
                    {preferences.workoutDuration} minutes
                  </span>
                </p>
              </div>
            </ScrollArea>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
      <Card className="mt-4 bg-card shadow-lg border-border">
        <CardHeader className="bg-white border-b border-border">
          <CardTitle className="text-lg sm:text-xl text-foreground">
            Design Your Perfect Workout
          </CardTitle>
          <CardDescription className="text-xs text-muted-foreground">
            Step {step} of 4:{" "}
            {step === 1
              ? "Personal Info"
              : step === 2
              ? "Fitness Goals"
              : step === 3
              ? "Workout Preferences"
              : "Review"}
          </CardDescription>
          <div className="w-full bg-secondary h-2 mt-2 rounded-full overflow-hidden">
            <div
              className="bg-primary h-2 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${(step / 4) * 100}%` }}
            ></div>
          </div>
        </CardHeader>
        <CardContent className="mt-6 px-4 sm:px-6">
          {renderStepContent()}
        </CardContent>
        <CardFooter className="flex justify-between bg-white border-t border-border px-4 sm:px-6 py-4">
          {step > 1 && (
            <Button
              type="button"
              variant="outline"
              onClick={handlePrev}
              className="text-xs text-muted-foreground hover:bg-secondary"
            >
              <ChevronLeft className="w-4 h-4 mr-2" />
              Previous
            </Button>
          )}
          <Button
            type="button"
            onClick={handleNext}
            className={`text-xs ml-auto bg-primary text-primary-foreground hover:bg-primary/90 ${
              step < 4 ? 'px-3 py-1' : 'px-2 py-1'
            }`}
          >
            {step < 4 ? (
              <>
                Next
                <ChevronRight className="w-3 h-3 ml-1" />
              </>
            ) : (
              <>
                Create My Workout
                <Dumbbell className="w-3 h-3 ml-1" />
              </>
            )}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default QuestionDesigner;
