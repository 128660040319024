const exerciseLibrary = [
  {
    id: 1,
    name: "Push-ups",
    difficulty: "Beginner",
    bodyPart: "Chest",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "IODxDxX7oi4",
    tips: "Keep your back straight and engage your core for stability.",
    targetGoals: ["Build Muscle", "Overall Health"],
  },
  {
    id: 2,
    name: "Squats",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "sXkq3pD6Zms",
    tips: "Keep your knees behind your toes and engage your glutes as you rise.",
    targetGoals: ["Lower Body Strength", "Tone Muscle"],
  },
  {
    id: 3,
    name: "Plank",
    difficulty: "Beginner",
    bodyPart: "Core",
    equipment: "Bodyweight",
    type: ["Strength", "Endurance"],
    isHIIT: false,
    youtubeId: "ASdvN_XEl_c",
    tips: "Maintain a straight line from your head to your heels.",
    targetGoals: ["Core Stability", "Improve Posture"],
  },
  {
    id: 4,
    name: "Burpees",
    difficulty: "Intermediate",
    bodyPart: "Full Body",
    equipment: "Bodyweight",
    type: ["Strength", "Cardio"],
    isHIIT: true,
    youtubeId: "TU8QYVW0gDU",
    tips: "Explode up as you jump and land softly before lowering to the ground.",
    targetGoals: ["Increase Stamina", "Fat Loss"],
  },
  {
    id: 5,
    name: "Mountain Climbers",
    difficulty: "Intermediate",
    bodyPart: "Core",
    equipment: "Bodyweight",
    type: ["Cardio"],
    isHIIT: true,
    youtubeId: "nmwgirgXLYM",
    tips: "Drive your knees towards your chest while maintaining a plank position.",
    targetGoals: ["Cardio Endurance", "Core Strength"],
  },
  {
    id: 6,
    name: "Dumbbell Bench Press",
    difficulty: "Intermediate",
    bodyPart: "Chest",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "gRVjAtPip0Y",
    tips: "Lower the weights slowly and press up explosively.",
    targetGoals: ["Upper Body Strength", "Build Muscle"],
  },
  {
    id: 7,
    name: "Deadlifts",
    difficulty: "Advanced",
    bodyPart: "Back",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "r4MzxtBKyNE",
    tips: "Keep your back flat and lift with your legs, not your back.",
    targetGoals: ["Full Body Strength", "Power"],
  },
  {
    id: 8,
    name: "Jump Rope",
    difficulty: "Beginner",
    bodyPart: "Full Body",
    equipment: "Jump Rope",
    type: ["Cardio"],
    isHIIT: true,
    youtubeId: "N8d7sU_kNEg",
    tips: "Keep your elbows close to your body and maintain a steady rhythm.",
    targetGoals: ["Cardiovascular Health", "Fat Loss"],
  },
  {
    id: 9,
    name: "Pull-ups",
    difficulty: "Advanced",
    bodyPart: "Back",
    equipment: "Pull-up Bar",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "eGo4IYlbE5g",
    tips: "Engage your back muscles and avoid swinging your body.",
    targetGoals: ["Upper Body Strength", "Build Muscle"],
  },
  {
    id: 10,
    name: "Lunges",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "QOVaHwm-Q6U",
    tips: "Step forward with control, keeping your knees aligned.",
    targetGoals: ["Lower Body Strength", "Tone Muscle"],
  },
  {
    id: 11,
    name: "Bicycle Crunches",
    difficulty: "Beginner",
    bodyPart: "Core",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "9FGilxCbdz8",
    tips: "Keep your lower back pressed against the floor throughout the movement.",
    targetGoals: ["Core Strength", "Tone Abs"],
  },
  {
    id: 12,
    name: "Dips",
    difficulty: "Intermediate",
    bodyPart: "Triceps",
    equipment: "Parallel Bars",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "2z8JmcrW-As",
    tips: "Keep your elbows close to your body and lower yourself slowly.",
    targetGoals: ["Upper Body Strength", "Build Muscle"],
  },
  {
    id: 13,
    name: "Russian Twists",
    difficulty: "Beginner",
    bodyPart: "Core",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "wkD8rjkodUI",
    tips: "Keep your feet off the ground and twist from your core, not your arms.",
    targetGoals: ["Core Strength", "Oblique Definition"],
  },
  {
    id: 14,
    name: "Box Jumps",
    difficulty: "Intermediate",
    bodyPart: "Legs",
    equipment: "Box",
    type: ["Plyometric"],
    isHIIT: true,
    youtubeId: "52r_Ul5k03g",
    tips: "Land softly with your knees slightly bent to absorb the impact.",
    targetGoals: ["Explosive Power", "Lower Body Strength"],
  },
  {
    id: 15,
    name: "Kettlebell Swings",
    difficulty: "Intermediate",
    bodyPart: "Full Body",
    equipment: "Kettlebell",
    type: ["Strength", "Cardio"],
    isHIIT: true,
    youtubeId: "YSxHifyI6s8",
    tips: "Drive the movement from your hips, not your arms.",
    targetGoals: ["Full Body Strength", "Cardiovascular Endurance"],
  },
  {
    id: 16,
    name: "Lat Pulldowns",
    difficulty: "Beginner",
    bodyPart: "Back",
    equipment: "Cable Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "CAwf7n6Luuc",
    tips: "Keep your chest up and pull the bar down to your upper chest.",
    targetGoals: ["Back Strength", "Improve Posture"],
  },
  {
    id: 17,
    name: "Leg Press",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Leg Press Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "IZxyjW7MPJQ",
    tips: "Don't lock your knees at the top of the movement.",
    targetGoals: ["Lower Body Strength", "Build Muscle"],
  },
  {
    id: 18,
    name: "Tricep Pushdowns",
    difficulty: "Beginner",
    bodyPart: "Triceps",
    equipment: "Cable Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "2-LAMcpzODU",
    tips: "Keep your elbows close to your body throughout the movement.",
    targetGoals: ["Arm Strength", "Tone Arms"],
  },
  {
    id: 19,
    name: "Seated Rows",
    difficulty: "Beginner",
    bodyPart: "Back",
    equipment: "Cable Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "GZbfZ033f74",
    tips: "Squeeze your shoulder blades together at the end of the movement.",
    targetGoals: ["Back Strength", "Improve Posture"],
  },
  {
    id: 20,
    name: "Calf Raises",
    difficulty: "Beginner",
    bodyPart: "Calves",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "gwLzBJYoWlI",
    tips: "Rise up onto your toes as high as possible and lower slowly.",
    targetGoals: ["Calf Strength", "Leg Definition"],
  },
  {
    id: 21,
    name: "Hammer Curls",
    difficulty: "Beginner",
    bodyPart: "Biceps",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "zC3nLlEvin4",
    tips: "Keep your elbows close to your body and curl the weights towards your shoulders.",
    targetGoals: ["Arm Strength", "Build Muscle"],
  },
  {
    id: 22,
    name: "Leg Raises",
    difficulty: "Beginner",
    bodyPart: "Core",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "JB2oyawG9KI",
    tips: "Keep your lower back pressed against the floor throughout the movement.",
    targetGoals: ["Core Strength", "Lower Ab Definition"],
  },
  {
    id: 23,
    name: "Barbell Rows",
    difficulty: "Intermediate",
    bodyPart: "Back",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "T3N-TO4reLQ",
    tips: "Keep your back straight and pull the bar towards your lower chest.",
    targetGoals: ["Back Strength", "Build Muscle"],
  },
  {
    id: 24,
    name: "Wall Sits",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Wall",
    type: ["Strength", "Endurance"],
    isHIIT: false,
    youtubeId: "y-wV4Venusw",
    tips: "Keep your back flat against the wall and your thighs parallel to the ground.",
    targetGoals: ["Leg Endurance", "Tone Thighs"],
  },
  {
    id: 25,
    name: "Jumping Jacks",
    difficulty: "Beginner",
    bodyPart: "Full Body",
    equipment: "Bodyweight",
    type: ["Cardio"],
    isHIIT: true,
    youtubeId: "c4DAnQ6DtF8",
    tips: "Land softly on the balls of your feet and keep a steady rhythm.",
    targetGoals: ["Cardiovascular Health", "Warm-up"],
  },
  {
    id: 26,
    name: "Dumbbell Shoulder Press",
    difficulty: "Intermediate",
    bodyPart: "Shoulders",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "qEwKCR5JCog",
    tips: "Keep your core engaged and press the weights directly overhead.",
    targetGoals: ["Shoulder Strength", "Build Muscle"],
  },
  {
    id: 27,
    name: "Glute Bridges",
    difficulty: "Beginner",
    bodyPart: "Glutes",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "GkzRLUWfy5A",
    tips: "Squeeze your glutes at the top of the movement and hold for a second.",
    targetGoals: ["Glute Strength", "Improve Posture"],
  },
  {
    id: 28,
    name: "Tricep Dips",
    difficulty: "Beginner",
    bodyPart: "Triceps",
    equipment: "Bench",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "6kALZikXxLc",
    tips: "Keep your shoulders down and away from your ears throughout the movement.",
    targetGoals: ["Arm Strength", "Tone Arms"],
  },
  {
    id: 29,
    name: "Lateral Raises",
    difficulty: "Beginner",
    bodyPart: "Shoulders",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "3VcKaXpzqRo",
    tips: "Raise the weights to shoulder height and slightly tilt the dumbbells as you lift.",
    targetGoals: ["Shoulder Definition", "Build Muscle"],
  },
  {
    id: 30,
    name: "Reverse Lunges",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Bodyweight",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "YZJV8AM3eNs",
    tips: "Step back with control and lower your back knee towards the ground.",
    targetGoals: ["Lower Body Strength", "Balance"],
  },
  {
    id: 31,
    name: "Plank Jacks",
    difficulty: "Intermediate",
    bodyPart: "Full Body",
    equipment: "Bodyweight",
    type: ["Cardio", "Strength"],
    isHIIT: true,
    youtubeId: "Q3Zt0tqkeaM",
    tips: "Maintain a strong plank position as you jump your feet out and in.",
    targetGoals: ["Core Strength", "Cardio Endurance"],
  },
  {
    id: 32,
    name: "Dumbbell Lunges",
    difficulty: "Intermediate",
    bodyPart: "Legs",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "D7KaRcUTQeE",
    tips: "Keep your upper body straight and step forward with control.",
    targetGoals: ["Lower Body Strength", "Balance"],
  },
  {
    id: 33,
    name: "Seated Leg Curls",
    difficulty: "Beginner",
    bodyPart: "Hamstrings",
    equipment: "Leg Curl Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "ELOCsoDSmrg",
    tips: "Curl your legs towards your buttocks and squeeze at the top of the movement.",
    targetGoals: ["Hamstring Strength", "Leg Definition"],
  },
  {
    id: 34,
    name: "Chest Flyes",
    difficulty: "Intermediate",
    bodyPart: "Chest",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "eozdVDA78K0",
    tips: "Keep a slight bend in your elbows and focus on using your chest muscles.",
    targetGoals: ["Chest Definition", "Build Muscle"],
  },
  {
    id: 35,
    name: "Leg Extensions",
    difficulty: "Beginner",
    bodyPart: "Quadriceps",
    equipment: "Leg Extension Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "YyvSfVjQeL0",
    tips: "Extend your legs fully and squeeze your quads at the top of the movement.",
    targetGoals: ["Quad Strength", "Leg Definition"],
  },
  {
    id: 36,
    name: "Dumbbell Rows",
    difficulty: "Beginner",
    bodyPart: "Back",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "roCP6wCXPqo",
    tips: "Keep your back straight and pull the dumbbell towards your hip.",
    targetGoals: ["Back Strength", "Improve Posture"],
  },
  {
    id: 37,
    name: "Skull Crushers",
    difficulty: "Intermediate",
    bodyPart: "Triceps",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "d_KZxkY_0cM",
    tips: "Keep your elbows pointing towards the ceiling throughout the movement.",
    targetGoals: ["Arm Strength", "Build Muscle"],
  },
  {
    id: 38,
    name: "Seated Calf Raises",
    difficulty: "Beginner",
    bodyPart: "Calves",
    equipment: "Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "JbyjNymZOt0",
    tips: "Push through the balls of your feet and squeeze your calves at the top.",
    targetGoals: ["Calf Strength", "Leg Definition"],
  },
  {
    id: 39,
    name: "Face Pulls",
    difficulty: "Intermediate",
    bodyPart: "Shoulders",
    equipment: "Cable Machine",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "rep-U9TAC1I",
    tips: "Pull the rope towards your face, keeping your upper arms parallel to the ground.",
    targetGoals: ["Shoulder Health", "Upper Back Strength"],
  },
  {
    id: 40,
    name: "Hanging Leg Raises",
    difficulty: "Advanced",
    bodyPart: "Core",
    equipment: "Pull-up Bar",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "Pr1ieGZ5atk",
    tips: "Keep your legs straight and raise them until they're parallel to the ground.",
    targetGoals: ["Core Strength", "Ab Definition"],
  },
  {
    id: 41,
    name: "Battle Ropes",
    difficulty: "Intermediate",
    bodyPart: "Full Body",
    equipment: "Battle Ropes",
    type: ["Cardio", "Strength"],
    isHIIT: true,
    youtubeId: "Qj2kBgHJlXo",
    tips: "Keep a steady rhythm and engage your core throughout the exercise.",
    targetGoals: ["Full Body Conditioning", "Cardio Endurance"],
  },
  {
    id: 42,
    name: "Barbell Hip Thrusts",
    difficulty: "Intermediate",
    bodyPart: "Glutes",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "Zp26q4BY5HE",
    tips: "Drive through your heels and squeeze your glutes at the top of the movement.",
    targetGoals: ["Glute Strength", "Lower Body Power"],
  },
  {
    id: 43,
    name: "Medicine Ball Slams",
    difficulty: "Intermediate",
    bodyPart: "Full Body",
    equipment: "Medicine Ball",
    type: ["Strength", "Cardio"],
    isHIIT: true,
    youtubeId: "CJt2mYDIfjQ",
    tips: "Use your whole body to slam the ball and catch it on the rebound.",
    targetGoals: ["Full Body Power", "Core Strength"],
  },
  {
    id: 44,
    name: "Incline Bench Press",
    difficulty: "Intermediate",
    bodyPart: "Chest",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "SrqOu55lrYU",
    tips: "Keep your feet flat on the ground and press the bar directly above your upper chest.",
    targetGoals: ["Upper Chest Strength", "Build Muscle"],
  },
  {
    id: 45,
    name: "Farmer's Walks",
    difficulty: "Beginner",
    bodyPart: "Full Body",
    equipment: "Dumbbells",
    type: ["Strength", "Cardio"],
    isHIIT: false,
    youtubeId: "Fkzk_RqlYig",
    tips: "Keep your shoulders back and walk with a normal stride.",
    targetGoals: ["Grip Strength", "Full Body Conditioning"],
  },
  {
    id: 46,
    name: "Bodyweight Dips",
    difficulty: "Intermediate",
    bodyPart: "Chest",
    equipment: "Parallel Bars",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "2z8JmcrW-As",
    tips: "Lower yourself until your upper arms are parallel to the ground.",
    targetGoals: ["Chest Strength", "Tricep Development"],
  },
  {
    id: 47,
    name: "Barbell Shrugs",
    difficulty: "Beginner",
    bodyPart: "Trapezius",
    equipment: "Barbell",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "cJRVVxmytaM",
    tips: "Lift your shoulders straight up towards your ears and hold briefly.",
    targetGoals: ["Trap Development", "Upper Back Strength"],
  },
  {
    id: 48,
    name: "Box Step-Ups",
    difficulty: "Beginner",
    bodyPart: "Legs",
    equipment: "Box",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "yCcSjrFjTZE",
    tips: "Step up onto the box, driving through your heel to fully extend your hip and knee.",
    targetGoals: ["Lower Body Strength", "Balance"],
  },
  {
    id: 49,
    name: "Decline Push-Ups",
    difficulty: "Intermediate",
    bodyPart: "Chest",
    equipment: "Bench",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "SKPab2YC8BE",
    tips: "Keep your core tight and lower your chest towards the ground.",
    targetGoals: ["Upper Chest Strength", "Tricep Development"],
  },
  {
    id: 50,
    name: "Renegade Rows",
    difficulty: "Advanced",
    bodyPart: "Back",
    equipment: "Dumbbells",
    type: ["Strength"],
    isHIIT: false,
    youtubeId: "PJpTBj4ilZw",
    tips: "Maintain a plank position and pull the dumbbell towards your hip.",
    targetGoals: ["Core Stability", "Back Strength"],
  },
];

export default exerciseLibrary;
