"use client";

import React, { useState, useEffect } from "react";
import {
  Activity,
  BarChart2,
  Target,
  Dumbbell,
  Clock,
  Youtube,
  HelpCircle,
  ChevronRight,
} from "lucide-react";
import { Button } from "../components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Progress } from "../components/ui/progress";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { useAuth } from "../contexts/AuthContext";
import { saveCompletedWorkout } from "../utils/api";
import { useToast } from "../components/ui/use-toast";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../components/ui/card";
import { Dialog, DialogContent } from "../components/ui/dialog";

const WorkoutDisplay = ({ workout }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [totalWorkoutTime, setTotalWorkoutTime] = useState(0);
  const [isResting, setIsResting] = useState(false);
  const [restTime, setRestTime] = useState(0);
  const [currentSet, setCurrentSet] = useState(1);

  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalWorkoutTime((prevTime) => prevTime + 1);
      if (isResting) {
        setRestTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            setIsResting(false);
            return 0;
          }
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isResting]);

  if (!workout || workout.length === 0) {
    return null;
  }

  const generateSetsAndReps = (exercise) => {
    const { difficulty, bodyPart } = exercise;
    let baseSets = 3;
    let baseReps = 10;

    if (difficulty === "beginner") baseSets = 2;
    else if (difficulty === "advanced") baseSets = 4;

    if (["legs", "back"].includes(bodyPart)) baseReps = 12;
    else if (["arms", "shoulders"].includes(bodyPart)) baseReps = 8;

    return { sets: baseSets, reps: baseReps };
  };

  const currentExercise = workout[currentExerciseIndex];
  const { sets, reps } = generateSetsAndReps(currentExercise);

  const handleNextExercise = () => {
    if (currentSet < sets) {
      setCurrentSet(currentSet + 1);
      setIsResting(true);
      setRestTime(45); // Changed rest time to 45 seconds
    } else if (currentExerciseIndex < workout.length - 1) {
      setCurrentExerciseIndex(currentExerciseIndex + 1);
      setCurrentSet(1);
      setIsResting(true);
      setRestTime(45); // Changed rest time between exercises to 45 seconds
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleWorkoutComplete = async () => {
    if (user) {
      try {
        const completedWorkout = {
          exercises: workout.map((exercise) => ({
            name: exercise.name,
            sets: exercise.sets,
            reps: exercise.reps,
            completed: true,
          })),
          date: new Date().toISOString(),
        };
        await saveCompletedWorkout(user.uid, completedWorkout);
        toast({
          title: "Success",
          description: "Workout completed and saved!",
          duration: 3000,
        });
      } catch (error) {
        console.error("Error saving completed workout:", error);
        toast({
          title: "Error",
          description: "Failed to save completed workout",
          variant: "destructive",
          duration: 3000,
        });
      }
    }
  };

  return (
    <div className="workout-display space-y-4 p-2 max-w-sm mx-auto">
      <Accordion type="single" collapsible className="w-full mb-4">
        <AccordionItem value="overview" className="border rounded-lg shadow-md">
          <AccordionTrigger className="text-sm font-semibold text-gray-900 hover:text-green-600 p-2">
            <div className="flex text-lg items-center">
              <Activity className="w-4 h-4 mr-2" />
              View Your Workout
            </div>
          </AccordionTrigger>
          <AccordionContent className="bg-gray-50 p-2">
            <ol className="list-decimal list-inside space-y-1 text-xs">
              {workout.map((exercise, index) => (
                <li
                  key={index}
                  className={`py-1 px-2 rounded ${
                    index === currentExerciseIndex
                      ? "font-bold text-green-600 bg-green-100"
                      : "text-gray-700 hover:bg-gray-200 transition-colors duration-200"
                  }`}
                >
                  {exercise.name}
                  <span className="text-gray-500 ml-1">
                    ({generateSetsAndReps(exercise).sets} x {generateSetsAndReps(exercise).reps})
                  </span>
                </li>
              ))}
            </ol>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Progress
        value={((currentExerciseIndex * sets + (currentSet - 1)) / (workout.length * sets)) * 100}
        className="mb-4 h-2 bg-gray-200"
      />
      <Card className="bg-white shadow-md">
        <CardHeader className="p-3">
          <CardTitle className="text-base font-semibold text-gray-900">
            {currentExercise.name}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-1">
          <div className="grid grid-cols-2 gap-1 mb-3 py-2 text-xs">
            <ExerciseDetail icon={Activity} label="Sets" value={sets} />
            <ExerciseDetail icon={Activity} label="Reps" value={reps} />
            <ExerciseDetail
              icon={Clock}
              label="Rest"
              value={currentSet < sets ? "30s" : "60s"}
            />
            <ExerciseDetail
              icon={BarChart2}
              label="Difficulty"
              value={currentExercise.difficulty}
            />
            <ExerciseDetail
              icon={Dumbbell}
              label="Equipment"
              value={currentExercise.equipment}
            />
            <ExerciseDetail
              icon={Target}
              label="Body Part"
              value={currentExercise.bodyPart}
            />
          </div>
          <div className="flex justify-between items-center mb-3 text-lg">
            <div className="font-bold text-gray-900">
              Total: {formatTime(totalWorkoutTime)}
            </div>
            {isResting && (
              <div className="font-bold text-green-600">
                Rest: {formatTime(restTime)}
              </div>
            )}
          </div>
          <div className="flex space-x-2 mb-3">
            <Button
              size="sm"
              variant="outline"
              onClick={() => setSelectedVideo(currentExercise.youtubeId)}
              className="flex-1 text-[10px] py-1 px-2 text-gray-700 border-gray-300 hover:bg-gray-100"
            >
              <Youtube className="w-3 h-3 mr-1" />
              Demo
            </Button>
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  size="sm"
                  variant="outline"
                  className="flex-1 text-[10px] py-1 px-2 text-gray-700 border-gray-300 hover:bg-gray-100"
                >
                  <HelpCircle className="w-3 h-3 mr-1" />
                  Tips
                </Button>
              </SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>{currentExercise.name} Tips</SheetTitle>
                  <SheetDescription>{currentExercise.tips}</SheetDescription>
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        </CardContent>
        <CardFooter className="flex flex-col space-y-2 p-3">
          <Button
            onClick={handleNextExercise}
            disabled={isResting}
            className="w-full text-xs text-white hover:bg-opacity-90 bg-blue-600"
          >
            {isResting
              ? "Resting..."
              : currentSet < sets
              ? `Next Set (${currentSet}/${sets})`
              : currentExerciseIndex < workout.length - 1
              ? "Next Exercise"
              : "Finish Workout"}
            <ChevronRight className="w-3 h-3 ml-1" />
          </Button>
          <Button
            onClick={handleWorkoutComplete}
            disabled={!(currentExerciseIndex === workout.length - 1 && currentSet === sets)}
            className="w-full bg-green-600 text-xs text-white hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Complete Workout
          </Button>
        </CardFooter>
      </Card>
      <div className="text-xs text-gray-500 text-center">
        Exercise {currentExerciseIndex + 1} of {workout.length}
      </div>
      <Dialog
        open={!!selectedVideo}
        onOpenChange={() => setSelectedVideo(null)}
      >
        <DialogContent className="sm:max-w-[300px] p-0 overflow-hidden">
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${selectedVideo}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const ExerciseDetail = ({ icon: Icon, label, value }) => (
  <div className="flex items-center space-x-1">
    <Icon className="w-4 h-4 text-green-600" />
    <span className="text-xs text-gray-500">{label}:</span>
    <span className="text-xs font-semibold text-gray-900">{value}</span>
  </div>
);

export default WorkoutDisplay;
