import React, { useState } from "react";
import QuestionDesigner from "./QuestionDesigner";
import WorkoutGenerator from "../utils/WorkoutGenerator";
import { Button } from "../components/ui/button";
import WorkoutDisplay from "./WorkoutDisplay";
import { Loader2, RefreshCw, Save, RotateCcw } from "lucide-react";
import { useAuth } from "../contexts/AuthContext";
import { saveWorkout, savePreferences } from "../utils/api";
import { Toaster } from "../components/ui/toaster";
import { useToast } from "../components/ui/use-toast";
import { Card, CardContent, CardFooter } from "../components/ui/card";

const WorkoutDesigner = () => {
  const [preferences, setPreferences] = useState(null);
  const [workout, setWorkout] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const { toast } = useToast();

  const handlePreferencesSubmit = async (newPreferences) => {
    console.log("Received preferences in WorkoutDesigner:", newPreferences);
    setPreferences(newPreferences);

    if (user) {
      try {
        const result = await savePreferences(newPreferences);
        console.log("Preferences saved:", result);
        toast({
          title: "Success",
          description: "Preferences saved successfully!",
          duration: 3000,
        });
      } catch (error) {
        console.error("Error saving preferences:", error);
        toast({
          title: "Error",
          description: "Failed to save preferences. Please try again.",
          variant: "destructive",
          duration: 3000,
        });
      }
    }

    setIsGenerating(true);
    setError(null);
    try {
      const generatedWorkout = WorkoutGenerator.generateWorkout(newPreferences);
      console.log("Generated workout:", generatedWorkout);
      if (!generatedWorkout || generatedWorkout.length === 0) {
        throw new Error(
          "No suitable exercises found for the given preferences"
        );
      }
      setWorkout(generatedWorkout);
      setShowForm(false);
      toast({
        title: "Success",
        description: "Workout generated successfully!",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error generating workout:", error);
      setWorkout(null);
      setError(
        error.message || "Failed to generate workout. Please try again."
      );
      toast({
        title: "Error",
        description:
          error.message || "Failed to generate workout. Please try again.",
        variant: "destructive",
        duration: 3000,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRegenerateWorkout = () => {
    if (user && preferences) {
      handlePreferencesSubmit(preferences);
    } else if (!user) {
      toast({
        title: "Error",
        description: "Please sign in to regenerate workouts.",
        variant: "destructive",
        duration: 3000,
      });
    }
  };

  const handleResetForm = () => {
    setWorkout(null);
    setShowForm(true);
    setError(null);
  };

  const handleSaveWorkout = async (workout) => {
    if (!user) {
      toast({
        title: "Action Required",
        description: "Please sign in or create a profile to save workouts.",
        variant: "destructive",
        duration: 5000,
      });
      return;
    }

    try {
      await saveWorkout(user.uid, workout);
      toast({
        title: "Success",
        description: "Workout saved successfully!",
      });
    } catch (error) {
      console.error("Error saving workout:", error);
      toast({
        title: "Error",
        description: "Failed to save workout. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow container mx-auto px-4 py-8 max-w-4xl">
        <Card className="shadow-lg">
          <CardContent className="p-6">
            {showForm ? (
              <QuestionDesigner
                onPreferencesSubmit={handlePreferencesSubmit}
                showForm={showForm}
              />
            ) : (
              <>
                {isGenerating ? (
                  <div className="text-center py-12">
                    <Loader2 className="w-12 h-12 animate-spin mx-auto mb-4 text-primary" />
                    <p className="text-lg text-foreground">
                      Generating your personalized workout...
                    </p>
                  </div>
                ) : (
                  <>
                    <WorkoutDisplay workout={workout} />
                    {error && (
                      <div className="mt-4 p-3 bg-destructive/10 border border-destructive text-destructive rounded-md text-sm">
                        {error}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>
          {workout && !showForm && (
            <CardFooter className="flex flex-col sm:flex-row gap-4 p-6">
              <div className="relative w-full sm:w-auto">
                <Button
                  onClick={handleRegenerateWorkout}
                  className="w-full sm:w-auto bg-primary hover:bg-primary/90 text-primary-foreground text-xs py-2 px-3"
                  disabled={!user}
                >
                  <RefreshCw className="w-4 h-4 mr-2" />
                  Regenerate Workout
                </Button>
                {!user && (
                  <div className="absolute inset-0 flex items-center justify-center bg-background/80 rounded">
                    <span className="text-xs text-muted-foreground">
                      Please sign in to regenerate Workouts
                    </span>
                  </div>
                )}
              </div>
              <Button
                onClick={() => handleSaveWorkout(workout)}
                className="w-full sm:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground"
              >
                <Save className="w-4 h-4 mr-2" />
                Save Workout
              </Button>
              <Button
                onClick={handleResetForm}
                variant="outline"
                className="w-full sm:w-auto border-input text-foreground hover:bg-accent hover:text-accent-foreground"
              >
                <RotateCcw className="w-4 h-4 mr-2" />
                Reset Form
              </Button>
            </CardFooter>
          )}
        </Card>
      </div>
      <Toaster />
    </div>
  );
};

export default WorkoutDesigner;
