import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

const API_URL = 'http://localhost:3001/api';

export async function saveWorkout(name, exercises) {
  console.log("Saving workout:", name, exercises);
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await fetch(`${API_URL}/save-workout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ name, exercises })
    });

    console.log("Save workout response:", response);

    if (!response.ok) {
      throw new Error('Failed to save workout');
    }

    const result = await response.json();
    console.log("Save workout result:", result);
    return result;
  } catch (error) {
    console.error('Error saving workout:', error);
    throw error;
  }
}

export async function getUserPreferences(userId) {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await fetch(`${API_URL}/user-preferences`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user preferences');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching user preferences:', error);
    throw error;
  }
}

export async function getUserWorkouts(userId) {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await fetch(`${API_URL}/user-workouts`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user workouts');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching user workouts:', error);
    throw error;
  }
}

export async function savePreferences(preferences) {
  console.log("Saving preferences:", preferences);
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await fetch(`${API_URL}/save-preferences`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ preferences })
    });

    console.log("Save preferences response:", response);

    if (!response.ok) {
      throw new Error('Failed to save preferences');
    }

    const result = await response.json();
    console.log("Save preferences result:", result);
    return result;
  } catch (error) {
    console.error('Error saving preferences:', error);
    throw error;
  }
}

export async function removeAllWorkouts(userId) {
  const token = await auth.currentUser.getIdToken();
  try {
    const response = await fetch(`${API_URL}/remove-all-workouts`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to remove all workouts');
    }

    return response.json();
  } catch (error) {
    console.error('Error removing all workouts:', error);
    throw error;
  }
}

export async function saveCompletedWorkout(userId, workoutData) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(`${API_URL}/save-completed-workout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId, workoutData })
  });
  return response.json();
}

export async function getDailyProgress(userId) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(`${API_URL}/daily-progress`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch daily progress');
  }
  return response.json();
}

export async function setDailyGoal(userId, goal) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(`${API_URL}/set-daily-goal`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId, goal })
  });
  if (!response.ok) {
    throw new Error('Failed to set daily goal');
  }
  return response.json();
}

export async function getDailyGoal(userId) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(`${API_URL}/daily-goal`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch daily goal');
  }
  const data = await response.json();
  return data.goal;
}

export async function logWorkoutCompletion(userId) {
  const token = await auth.currentUser.getIdToken();
  const response = await fetch(`${API_URL}/log-workout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId })
  });
  if (!response.ok) {
    throw new Error('Failed to log workout');
  }
  return response.json();
}
