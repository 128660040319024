import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCQUuhcjWSr-pRfXGVKtKtvAS9wqzj6G-c",
  authDomain: "fitness-selector.firebaseapp.com",
  projectId: "fitness-selector",
  storageBucket: "fitness-selector.appspot.com",
  messagingSenderId: "657614297622",
  appId: "1:657614297622:web:7b6be1ad080f2c8db5b337",
  measurementId: "G-R1K59CPMPP",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth, analytics };
