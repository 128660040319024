"use client";

import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../contexts/AuthContext";
import {
  getUserPreferences,
  getUserWorkouts,
  removeAllWorkouts,
} from "../utils/api";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  BarChart,
  CalendarCheck,
  Dumbbell,
  Flame,
  Home,
  Menu,
  Settings,
  Trophy,
  User,
  Trash2,
} from "lucide-react";
import { Progress } from "../components/ui/progress";
import { ScrollArea } from "../components/ui/scroll-area";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { Badge } from "../components/ui/badge";
import { useToast } from "../components/ui/use-toast";
import { Toaster } from "../components/ui/toaster";


export default function UserDashboard() {
  const { user } = useAuth();
  const [preferences, setPreferences] = useState(null);
  const [workouts, setWorkouts] = useState([]);
  const [userStats, setUserStats] = useState(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  const fetchUserData = useCallback(async () => {
    if (!user) return;
    try {
      const [userPreferences, userWorkouts, stats] = await Promise.all([
        getUserPreferences(user.uid),
        getUserWorkouts(user.uid),
      ]);
      setPreferences(userPreferences);
      setWorkouts(userWorkouts || []);
      setUserStats(stats);
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast({
        title: "Error",
        description: "Failed to load user data. Please try again.",
        variant: "destructive",
      });
    }
  }, [user, toast]);

  useEffect(() => {
    if (user) {
      fetchUserData();
    } else {
      navigate("/");
    }
  }, [user, navigate, fetchUserData]);

  const handleRemoveAllWorkouts = async () => {
    try {
      await removeAllWorkouts(user.uid);
      setWorkouts([]);
      toast({
        title: "Success",
        description: "All saved workouts have been removed.",
      });
    } catch (error) {
      console.error("Error removing workouts:", error);
      toast({
        title: "Error",
        description: "Failed to remove workouts. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen ">
      <header className="sticky top-0 z-10 bg-white border-b border-gray-200 p-4">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-bold text-gray-900">Dashboard</h1>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="flex flex-col space-y-4">
                <Button variant="ghost" className="justify-start" onClick={() => navigate("/")}>
                  <Home className="mr-2 h-4 w-4" />
                  Dashboard
                </Button>
                <Button variant="ghost" className="justify-start" onClick={() => navigate("/workout-designer", { state: { step: 4 } })}>
                  <Dumbbell className="mr-2 h-4 w-4" />
                  Workouts
                </Button>
                <div className="relative">
                  <Button variant="ghost" className="justify-start w-full opacity-50" disabled>
                    <CalendarCheck className="mr-2 h-4 w-4" />
                    Calendar
                  </Button>
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
                    <span className="text-white text-xs font-bold">Coming Soon</span>
                  </div>
                </div>
                <div className="relative">
                  <Button variant="ghost" className="justify-start w-full opacity-50" disabled>
                    <User className="mr-2 h-4 w-4" />
                    Profile
                  </Button>
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
                    <span className="text-white text-xs font-bold">Coming Soon</span>
                  </div>
                </div>
                <div className="relative">
                  <Button variant="ghost" className="justify-start w-full opacity-50" disabled>
                    <Settings className="mr-2 h-4 w-4" />
                    Settings
                  </Button>
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
                    <span className="text-white text-xs font-bold">Coming Soon</span>
                  </div>
                </div>
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </header>

      <main className="flex-1 p-4 space-y-6">
        <section className="space-y-2">
          <h2 className="text-2xl font-bold text-gray-900">Welcome back,</h2>
          <p className="text-3xl font-extrabold text-primary">
            {preferences?.name ||
              user.displayName?.split(" ")[0] ||
              user.email?.split("@")[0] ||
              "Fitness Enthusiast"}
          </p>
        </section>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center text-lg">
              <Flame className="mr-2 h-5 w-5 text-primary" />
              Streak
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-4xl font-bold text-center">
              {userStats?.currentStreak || 0}
            </div>
            <p className="text-sm text-center mt-2">days in a row</p>
            <Progress
              value={((userStats?.currentStreak || 0) % 7 / 7) * 100}
              className="h-2 mt-4"
            />
            <p className="text-xs text-center mt-2">7-day goal</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center text-lg">
              <Settings className="mr-2 h-5 w-5 text-primary" />
              Preferences
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="preferences">
                <AccordionTrigger className="text-lg font-semibold">View Preferences</AccordionTrigger>
                <AccordionContent>
                  <ScrollArea className="h-[300px] pr-4">
                    {preferences &&
                      Object.entries(preferences).map(([key, value]) => (
                        <div key={key} className="mb-4 pb-3 border-b border-gray-200 last:border-b-0">
                          <span className="font-medium text-gray-700 capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}: </span>
                          {Array.isArray(value) ? (
                            <div className="flex flex-wrap gap-2 mt-2">
                              {value.map((item, index) => (
                                <Badge key={index} variant="secondary" className="px-2 py-1 text-sm">
                                  {item}
                                </Badge>
                              ))}
                            </div>
                          ) : (
                            <span className="text-gray-600">{value?.toString() ?? "Not set"}</span>
                          )}
                        </div>
                      ))}
                  </ScrollArea>
                  <Button
                    onClick={() => navigate("/update-preferences")}
                    className="w-full mt-6 bg-primary text-primary-foreground hover:bg-primary/90 transition-colors duration-200"
                  >
                    <Settings className="mr-2 h-5 w-5" />
                    Update Preferences
                  </Button>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="flex items-center text-lg">
              <Dumbbell className="mr-2 h-5 w-5 text-primary" />
              Saved Workouts
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="workouts">
                <AccordionTrigger className="text-lg font-semibold">View Saved Workouts</AccordionTrigger>
                <AccordionContent>
                  <ScrollArea className="h-[250px] pr-4">
                    {workouts.map((workout, index) => (
                      <div key={index} className="mb-6 pb-4 border-b border-gray-200 last:border-b-0">
                        <h4 className="font-medium text-primary mb-2">
                          {new Date(workout.createdAt).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        </h4>
                        <ul className="space-y-2">
                          {workout.exercises.map((exercise, idx) => (
                            <li key={idx} className="flex items-center">
                              <Dumbbell className="h-4 w-4 mr-2 text-gray-400" />
                              <span className="text-sm text-gray-600">{exercise.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </ScrollArea>
                  <Button
                    onClick={handleRemoveAllWorkouts}
                    className="w-full mt-6 bg-destructive text-destructive-foreground hover:bg-destructive/90 transition-colors duration-200"
                  >
                    <Trash2 className="mr-2 h-5 w-5" />
                    Remove All Saved Workouts
                  </Button>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>

        <div className="grid gap-4 md:grid-cols-2">
          <div className="relative">
            <Button
              disabled
              className="w-full bg-secondary text-secondary-foreground hover:bg-secondary/90 opacity-50"
            >
              <BarChart className="mr-2 h-5 w-5" />
              View Detailed Stats
            </Button>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
              <span className="text-white font-bold">Coming Soon</span>
            </div>
          </div>
          <div className="relative">
            <Button
              disabled
              className="w-full bg-secondary text-secondary-foreground hover:bg-secondary/90 opacity-50"
            >
              <Trophy className="mr-2 h-5 w-5" />
              View Achievements
            </Button>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md">
              <span className="text-white font-bold">Coming Soon</span>
            </div>
          </div>
        </div>

        <Button
          onClick={() => {
            navigate("/workout-designer", { 
              state: { 
                step: 4, 
                preferences: preferences 
              } 
            });
          }}
          className="w-full bg-primary text-primary-foreground hover:bg-primary/90"
        >
          <Dumbbell className="mr-2 h-5 w-5" />
          Generate Workout
        </Button>
      </main>

      <Toaster />
    </div>
  );
}
